import { useDropzone } from 'react-dropzone';
import React, { useState } from 'react';
import './MyDropzoneComponent.css'; // Import your CSS file

const MyDropzoneComponent = ({ handleImageLoad }) => {
  const [image, setImage] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    handleImageLoad(file);
    setIsActive(false); // Reset active state after dropping file
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    onDragEnter: () => setIsActive(true),
    onDragLeave: () => setIsActive(false),
  });

  return (
    <div
      {...getRootProps()}
      className={`image-editor-browse-button ${isActive ? 'active' : ''}`}
    >
      <input {...getInputProps()} />
      <p className='dropzone-text'>Drop an image here, or click to select an image</p>
      {image && (
        <div className='image-preview'>
          <img src={image.src} alt='Preview' style={{ width: image.width, height: image.height }} />
        </div>
      )}
    </div>
  );
};

export default MyDropzoneComponent;
