import React from 'react';
import './Links.css';
// import coingecko from '../assets/icons/coingeckoturquoise.png'
import coingecko from '../assets/icons/coingeckoblack.png'
import x from '../assets/icons/xblack.png'
import telegram from '../assets/icons/telegramblack.png'
import dexscreener from '../assets/icons/dexscreenerblack.png'

export default function Links() {
  return (
    <div className="Links">
      {/* <a href="test" className="LinkContainer" target="_blank" rel="noopener noreferrer">
        <img className="LinkImage" src={coingecko} alt="link" />
      </a> */}
      <a href="https://twitter.com/BipoMagicMoney" className="LinkContainer" target="_blank" rel="noopener noreferrer">
        <img className="LinkImage" src={x} alt="link" />
      </a>
      <a href="https://t.me/MagicalFriendBipo" className="LinkContainer" target="_blank" rel="noopener noreferrer">
        <img className="LinkImage" src={telegram} alt="link" />
      </a>
      {/* <a href="test" className="LinkContainer" target="_blank" rel="noopener noreferrer">
        <img className="LinkImage" src={dexscreener} alt="link" />
      </a> */}
    </div>
  );
}