import React, { useEffect, useRef } from 'react';
import './Home.css';
import Links from '../Component/Links';
import bipo from '../assets/bipo.png';
import MagicButton from '../Component/MagicButton';

function Home() {
  const homeRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const numStars = 1; // Number of stars to spawn
      const cursorX = e.clientX;
      const cursorY = e.clientY;
      
      for (let i = 0; i < numStars; i++) {
        const glitterStar = document.createElement('div');
        glitterStar.className = 'glitter-star';
        
        const randX = cursorX + (Math.random() - 0.5) * 27 + window.scrollX;
        const randY = cursorY + (Math.random() - 0.5) * 27 + window.scrollY;
        
        glitterStar.style.left = `${randX}px`;
        glitterStar.style.top = `${randY}px`;
        
        homeRef.current.appendChild(glitterStar);

        setTimeout(() => {
          glitterStar.remove();
        }, 600);
      }
    };

    const homeElement = homeRef.current;
    homeElement.addEventListener('mousemove', handleMouseMove);

    return () => {
      homeElement.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="Home" ref={homeRef}>
      <img className="bipoImg" src={bipo} alt="bipo" />
      <div className="HomeMain">
        <MagicButton />
      </div>
      <div className="BottomContainer">
        {/* <CopyToClipboardButton textToCopy="0xBipo" textToShow="0xBipo" /> */}
        <div className="LinksContainer">
          <Links />
        </div>
      </div>
    </div>
  );
}

export default Home;
