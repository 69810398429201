import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import './ImageEditor.css';
import baseHat from '../assets/hats/1.png';
import baseHatOutline from '../assets/hats/2.png';
import squareHat from '../assets/hats/3.png';
import puppetHatOutline from '../assets/hats/4.png';
import pixelHat from '../assets/hats/5.png';
import puppetHat from '../assets/hats/6.png';
import hatLogo from '../assets/hats/7.png';
import miladyHat from '../assets/hats/8.png';
import ninehat from '../assets/hats/9.png';
import tenhat from '../assets/hats/10.png';
import glasses from '../assets/hats/11.png';
import glassefines from '../assets/hats/12.png';
import lasereyes from '../assets/hats/13.png';

import ImageWrapper from './ImageWrapper';
import MyDropzoneComponent from './MyDropzoneComponent';

// const imgToPasteSizes = {
//   baseHat: { width: 1163, height: 1025 },
//   baseHatOutline: { width: 697, height: 987 },
//   squareHat: { width: 1162, height: 840 },
//   puppetHatOutline: { width: 780, height: 1008 },
//   pixelHat: { width: 1220, height: 526 },
//   puppetHat: { width: 780, height: 1008 },
// };

const ImageEditor = () => {
  const [images, setImages] = useState([]);
  const [image, setImage] = useState(null);
  const [layerHeight, setLayerHeight] = useState(0); // Default layer height
  const [layerColor, setLayerColor] = useState('#ff0000'); // Default layer color
  const imageRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageToPasteDict = {
    glasses: glasses,
    glassefines: glassefines,
    lasereyes: lasereyes,
    baseHat: baseHat,
    baseHatOutline: baseHatOutline,
    squareHat: squareHat,
    puppetHatOutline: puppetHatOutline,
    pixelHat: pixelHat,
    puppetHat: puppetHat,
    hatLogo: hatLogo,
    miladyHat: miladyHat,
    ninehat: ninehat,
    tenhat: tenhat,
  };

  const handleHatClick = (hatName) => {
    if (!imageLoaded) {
      return;
    }
    const canvasRect = imageRef.current.getBoundingClientRect();
    const newItem = {
      id: `hat-pasted-${images.length + 1}`,
      src: imageToPasteDict[hatName],
      position: { x: 0, y: 0 },
    };
    setImages([...images, newItem]);
  };

  function handleImageLoad(file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const maxWidth = window.innerWidth * 0.9;
        const maxHeight = window.innerHeight * 0.9;
        const minWidth = window.innerWidth * 0.15;
        const minHeight = window.innerHeight * 0.15;
        let width = img.width;
        let height = img.height;

        // Resize logic
        if (width > maxWidth || height > maxHeight) {
          const ratio = Math.min(maxWidth / width, maxHeight / height);
          width *= ratio;
          height *= ratio;
        } else if (width < minWidth || height < minHeight) {
          const ratio = Math.max(minWidth / width, minHeight / height);
          width *= ratio;
          height *= ratio;
        }
        setImage({
          src: event.target.result,
          width,
          height,
        });
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
    setImageLoaded(true);
    const imageContainer = document.querySelector('.image-container');
    imageContainer.style.border = '1px solid #444444';
  }

  // const handleImageLoad = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     const img = new Image();
  //     img.onload = () => {
  //       const maxWidth = window.innerWidth * 0.9;
  //       const maxHeight = window.innerHeight * 0.9;
  //       const minWidth = window.innerWidth * 0.15;
  //       const minHeight = window.innerHeight * 0.15;
  //       let width = img.width;
  //       let height = img.height;

  //       // Resize logic
  //       if (width > maxWidth || height > maxHeight) {
  //         const ratio = Math.min(maxWidth / width, maxHeight / height);
  //         width *= ratio;
  //         height *= ratio;
  //       } else if (width < minWidth || height < minHeight) {
  //         const ratio = Math.max(minWidth / width, minHeight / height);
  //         width *= ratio;
  //         height *= ratio;
  //       }
  //       setImage({
  //         src: event.target.result,
  //         width,
  //         height,
  //       });
  //     };
  //     img.src = event.target.result;
  //   };
  //   reader.readAsDataURL(file);
  //   setImageLoaded(true);
  // };

  const handleLayerColorChange = (e) => {
    console.log(e);
    setLayerColor(e.target.value)
  };

  const handleSaveImage = () => {
    const imageContainer = document.querySelector('.image-container');
    imageContainer.style.border = 'none';
    html2canvas(imageRef.current).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL();
      link.download = 'combined-image.png';
      link.click();
    });
    imageContainer.style.border = '1px solid #444444';
  };

  const handleSetLayerHeight = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    const height = parseInt(e.target.value, 10);
    setLayerHeight(height >= 0 ? height : 0);
  };

  const handleDelete = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  return (
    <div className='image-editor-container'>
      {/* <div className='image-editor-title'>Magic Hat Factory</div> */}
      <div className='image-editor-title'>
        {'Magic Factory'.split('').map((char, index) => (
          <span key={index}>{char === ' ' ? '\u00A0' : char}</span>
        ))}
      </div>
      <MyDropzoneComponent handleImageLoad={handleImageLoad} />
      {/* <input className='image-editor-browse-button' type="file" accept="image/*" onChange={handleImageLoad} /> */}
      <div className="image-container" ref={imageRef}>
        {images.map((image) => (
          <ImageWrapper
            key={image.id}
            src={image.src}
            position={image.position}
            onDelete={() => handleDelete(image.id)}
          />
        ))}
        {image ? (
          <div className='image-loaded-container'>
            <div
              className="layer"
              style={{
                backgroundColor: layerColor,
                height: `${layerHeight}px`,
                width: '100%',
              }}
            />
            <img
              className='image-loaded'
              src={image.src}
              alt="Loaded"
              style={{
                display: 'block',
                width: `${image.width}px`,
                height: `${image.height}px`,
                marginTop: `${layerHeight}px`,
              }}
            />
          </div>
        ) : (
          <div className="placeholder">
            No image loaded
          </div>
        )}
      </div>
      <div className="image-editor-usage-note">
        The layer setting allows you to extend your images vertically. Change the layer height value to ensure they fit perfectly.
      </div>
      <div className="controls">
        <label className='layer-height'>
          <div className='layer-height-text'>Layer Height</div>
          <input
            className='layer-height-input'
            type="text"
            value={layerHeight}
            onChange={handleSetLayerHeight}
            style={{ marginLeft: '0.5rem' }}
          />
        </label>
        <label className='layer-color'>
          <div className='layer-color-text'>Layer Color</div>
          <input
            className='layer-color-input'
            type="color"
            value={layerColor}
            onInput={(e) => handleLayerColorChange(e)}
            style={{ marginLeft: '0.5rem' }}
          />
        </label>
        <button className='save-image-button' onClick={handleSaveImage}>
          Save Image
        </button>
      </div>
      <div className="image-to-paste">
        {Object.keys(imageToPasteDict).slice(3).map((name) => (
          <img
            key={name}
            src={imageToPasteDict[name]}
            alt={name}
            className='hat-to-paste'
            onClick={() => handleHatClick(name)}
            draggable="false"
          />
        ))}
      </div>
      <div className="image-to-paste">
        {Object.keys(imageToPasteDict).slice(0, 3).map((name) => (
          <img
            key={name}
            src={imageToPasteDict[name]}
            alt={name}
            className='hat-to-paste'
            onClick={() => handleHatClick(name)}
            draggable="false"
          />
        ))
        }
      </div>
    </div>
  );
};

export default ImageEditor;
