import React, { useEffect, useState } from 'react';
import './ArtGalery.css'; // Import your CSS file for styling

const ArtGalery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Function to import images dynamically from the 'assets/memes' folder
    const importAll = (r) => {
      return r.keys().map(r);
    };

    // Import all images from the 'assets/memes' folder
    const imageFiles = importAll(require.context('../assets/memes', false, /\.(png|gif|jpeg|jpg)$/));

    // Set state with imported images
    setImages(imageFiles);
  }, []);

  return (
    <div className="art-gallery">
      <div className='image-editor-title'>
        {'Bipo Museum'.split('').map((char, index) => (
          <span key={index}>{char === ' ' ? '\u00A0' : char}</span>
        ))}
      </div>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image} alt={`Art ${index}`} className="gallery-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArtGalery;
