import externalLinkIcon from '../assets/icons/externalLinkIcon.png';
import './MagicButton.css';
import React, { useEffect, useState } from 'react';

export default function MagicButton() {

  useEffect(() => {
    const handleHover = () => {
      const numStars = 35; // Number of stars to spawn
      const home = document.querySelector('.Home');
      for (let i = 0; i < numStars; i++) {
        const glitterStar = document.createElement('div');
        glitterStar.className = 'glitter-star-buy'; // Use correct class name

        const randX = Math.random() * (window.innerWidth - 50);
        const randY = Math.random() * (window.innerHeight - 50);

        glitterStar.style.left = `${randX}px`;
        glitterStar.style.top = `${randY}px`;

        home.appendChild(glitterStar);

        setTimeout(() => {
          glitterStar.remove();
        }, 2500);
      }
    };

    const button = document.querySelector('.buyButton');
    button.addEventListener('mouseenter', () => {
      handleHover();
      button.classList.add('hovered');
    });
    button.addEventListener('mouseleave', () => {
      button.classList.remove('hovered');
    });

    return () => {
      button.removeEventListener('mouseenter', handleHover);
      button.removeEventListener('mouseleave', () => {
        button.classList.remove('hovered');
      });
    };
  }, []);


  return (
    <a className='buyButton' href='https://magiceden.io/runes/MAGICAL%E2%80%A2FRIEND%E2%80%A2BIPO' target="_blank" rel="noopener noreferrer">
      <div>Buy</div>
      <img className='externalLinkIcon' src={externalLinkIcon} alt='externalLinkIcon' />
  </a>
  );
}