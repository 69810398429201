import React, { useRef, useEffect, useState } from 'react';
import interact from 'interactjs';
import './ImageWrapper.css'; // Assuming you have the relevant CSS

const MIN_SIZE = 50; // Set the minimum size for width and height

const ImageWrapper = ({ src, position, onDelete }) => {
  const imageRef = useRef(null);
  const [showBorders, setShowBorders] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (imageRef.current && !imageRef.current.contains(event.target)) {
        setShowBorders(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    const interactable = interact(imageRef.current);

    interactable.draggable({
      onmove: dragMoveListener,
    });

    interactable.resizable({
      edges: { left: true, right: true, bottom: true, top: true },
      modifiers: [
        interact.modifiers.aspectRatio({
          ratio: 'preserve',
        }),
        interact.modifiers.restrictSize({
          min: { width: MIN_SIZE, height: MIN_SIZE },
        }),
      ],
      listeners: {
        move: resizeMoveListener,
      },
    });

    function dragMoveListener(event) {
      const target = event.target;
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      target.style.transform = `translate(${x}px, ${y}px)`;
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);
    }

    function resizeMoveListener(event) {
      const target = event.target;
      let { width, height } = event.rect;
      const x = (parseFloat(target.getAttribute('data-x')) || 0);
      const y = (parseFloat(target.getAttribute('data-y')) || 0);

      target.style.width = width + 'px';
      target.style.height = height + 'px';
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);
    }

    return () => {
      interactable.unset();
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const toggleBorders = () => {
    setShowBorders(true);
  };

  return (
    <div
      ref={imageRef}
      className="image-wrapper"
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        cursor: 'move',
        outline: showBorders ? '2px dashed #ccc' : 'none', // Use outline instead of border
        boxSizing: 'border-box', // Ensure border-box sizing
      }}
      onClick={toggleBorders}
    >
      <img src={src} alt="resizable" className="resizable-image"/>
      {showBorders && (
        <div className="delete-icon" onClick={handleDelete}>
          &#10005;
        </div>
      )}
    </div>
  );
};

export default ImageWrapper;
