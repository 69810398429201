import React from 'react';
import Home from './Page/Home';
import ImageEditor from './Component/ImageEditor';
import { useState, useEffect } from 'react';
import ArtGalery from './Component/ArtGalery';

function App() {

  // const [isDesktop, setIsDesktop] = useState(false);

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia('(min-width: 130px)'); // Adjust the media query as per your desktop size criteria

  //   // Initial check on mount
  //   setIsDesktop(mediaQuery.matches);

  //   // Event listener for changes
  //   const handleMediaQueryChange = (e) => {
  //     setIsDesktop(e.matches);
  //   };

  //   mediaQuery.addEventListener('change', handleMediaQueryChange);

  //   // Clean up
  //   return () => {
  //     mediaQuery.removeEventListener('change', handleMediaQueryChange);
  //   };
  // }, []);


  return (
    <div className="App">
      <Home />
      <ImageEditor />
      <ArtGalery />
    </div>
  );
}

export default App;
